.MuiDrawer-paperAnchorRight {
  width: 21%;

  &.MuiPaper-root {
    background-color: #424242;
  }

  .cocktails-list {
    position: relative;
    top: 7%;
    margin-left: 12px;
  }

  img {
    border-radius: 5px;
  }

  .cocktail-detail {
    color: white;

    &:hover {
      color: darken(#cae4f1, 20%);
    }
  }
}
