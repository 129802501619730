.created-cocktail-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 10%;
  margin: auto;

  img {
    border-radius: 5px;
  }

  h1 {
    text-align: center;
  }
}
