@import "../../utilities/variables";

.post-create-form {
  height: 45vh;

  &.popup {
    width: 60%;
    max-width: 500px;
    overflow: scroll;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.2);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .inputs-container {
    display: grid;
    grid-template-columns: 70%;
    grid-template-rows: 20%;
    grid-gap: 20px;
    height: 95%;
    justify-content: center;
    padding-top: 55px;

    .input label {
      font-family: "Roboto Condensed";
    }

    .post-submit-button {
      height: 40px;
      color: white;
      font-weight: 600;
      background-color: $coolpurp;
      font-family: "Roboto Condensed";
      letter-spacing: 3px;
      text-transform: none;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
