@import "../../utilities/variables";

.image-upload-modal {
  outline: 0;

  .content {
    position: absolute;
    top: 30%;
    left: 40%;
    width: 350px;
    outline: none;
    background: white;
    border-radius: 10px;

    .save-button {
      position: relative;
      bottom: 15px;
      left: 30%;
      color: white;
      background-color: $coolpurp;
    }
  }
}
