@import "../../utilities/variables";

.signup-form-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90vh;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: left;
    width: 30%;
  }

  .signup-form {
    display: flex;
    flex-direction: column;
    width: 30%;

    label {
      margin-bottom: 20px;

      div {
        float: left;
        margin-bottom: 5px;
      }

      .MuiTextField-root {
        width: 100%;
      }
    }

    .signup-submit-button {
      height: 35px;
      border-radius: 3px;
      border-width: 0;
      background-color: $coolpurp;
    }
  }
}
