@import "../../utilities/variables";

.cocktail-details {
  margin-bottom: 50px;
  width: 500px;
  top: -75px;
  padding: 10px;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0 8px 0 rgba(0, 0, 0, 0.2);

  .cocktail-description {
    max-width: 500px;
    margin: auto;
  }

  .img-and-stats {
    text-align: center;

    img {
      height: auto;
      width: auto;
      max-width: 300px;
      max-height: 300px;
      border-radius: 10px;
    }
  }

  .stat {
    font-weight: 600;
    margin-right: 18px;

    img {
      height: 20px;
      width: 20px;
    }

    a {
      color: darken($coolpurp, 20%);
    }
  }

  .header {
    text-align: left;
    padding-bottom: 5px;
    border-bottom: 1px solid;
  }

  .content {
    word-wrap: break-word;
    text-align: left;
  }

  .ingredients-container {
    position: relative;
    margin: auto;
    text-align: left;
  }

  .heart-checkbox {
    display: inline-block;
    position: relative;
    right: 10px;
    bottom: 1px;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(1px 0px 4px darkred);
    }
  }

  .edit-text {
    text-align: center;
    margin-top: 7px;
  }

  .delete-text {
    margin-left: 30px;
    color: red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
