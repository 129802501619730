@import "../../utilities/variables";

.login-form-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90vh;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: left;
    width: 30%;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    width: 30%;

    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .forgot-password-text {
      margin-bottom: 10px;
      font-size: 11px;
      color: blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .signup-submit-button {
      height: 35px;
      border-radius: 3px;
      border-width: 0;
      background-color: $coolpurp;
    }
  }
}
