@import "../../utilities/variables";

.primary-navigation-bar {
  position: fixed;
  z-index: 9999;
  top: 0;
  display: flex;
  width: 100%;
  height: 60px;
  background-color: $coolpurp;

  .MuiIconButton-root {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
  }

  nav {
    display: flex;

    .nav-link {
      color: lavender;
      display: flex;
      align-items: center;
      margin-right: 10px;
      text-decoration: none;

      &.homepage {
        display: flex;
        width: 75px;
        font-size: 19px;
      }
    }

    .site-logo-nav {
      height: 30px;
      width: 30px;
    }

    .logo-text {
      font-family: "Sen";
      color: white;
      padding: 5px;
    }

    .cocktail-options-buttons {
      display: flex;
    }

    .user-options-dropdown {
      position: absolute;
      top: 10px;
      right: 0;
    }

    .login-signup-buttons {
      display: flex;
      position: absolute;
      right: 10px;
    }

    .logout-button {
      height: 30px;
      margin-top: 7px;
      background-color: lightgrey;
      position: absolute;
      right: 11px;
      -webkit-text-stroke: 0.1px;
    }
  }

  .left-nav {
    width: 25%;
  }

  .right-nav {
    width: 27%;
    align-items: center;
  }

  .search-bar {
    width: 500px;
    margin: auto;
  }
}
