@import "../../utilities/variables";

.cocktails-by-liquor-display {
  position: relative;
  top: 10%;
  display: flex;
  justify-content: center;

  h1 {
    text-align: center;
  }

  img {
    border-radius: 5px;
  }

  .MuiTabs-root {
    margin-bottom: 10px;

    .PrivateTabIndicator-root-1 {
      background-color: $coolpurp;
    }
  }
}
