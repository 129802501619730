@import "../../utilities/variables";

.create-cocktail-container {
  position: relative;
  width: 33%;
  height: 100vh;
  top: 11%;
  margin: auto;

  .create-cocktail-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;

    .cocktail-name-input {
      width: 100%;
      margin-bottom: 13px;
    }

    .instructions-input {
      width: 100%;
    }

    .cocktail-name-input {
      width: 100%;
    }

    .input-text-area {
      width: 100%;
      margin-top: 20px;
    }

    .dropdown-select {
      width: 100%;
      margin-bottom: 30px;

      &.complexity {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

    .fileContainer {
      p {
        letter-spacing: 1px;
      }
    }

    .private-cocktail-checkbox {
      width: 150px;
      position: relative;
      left: -36%;
    }

    .create-cocktail-submit-button {
      height: 40px;
      width: 45%;
      border-radius: 3px;
      border-width: 0;
      position: relative;
      margin-top: 10px;
      color: white;
      background-color: $coolpurp;
      font-family: "Roboto Condensed";
      letter-spacing: 3px;
      text-transform: none;
      font-weight: 400;

      input:focus {
        outline-width: 0;
      }
    }

    .invalid {
      border-width: 2px;
      border-color: red;
    }

    .help-icon {
      position: relative;
      left: 103%;
      bottom: 36px;
    }
  }
}
