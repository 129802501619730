@import "~bootstrap/scss/bootstrap";
@import "utilities/variables";
@import "react-notifications/lib/notifications";
@import url("https://fonts.googleapis.com/css2?family=Sen&family=Roboto+Condensed&display=swap");

body {
  margin: 0;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: lighten(black, 20%);

  label {
    input {
      width: 100%;
      height: 34px;
    }

    input,
    textarea {
      border-width: 1px;
      border-radius: 3px;
      border-color: hsl(0, 0%, 80%);
    }

    input:focus,
    textarea:focus {
      outline-width: 0;
      border-width: 2px;
      border-color: #2684ff;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $coolpurp;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
