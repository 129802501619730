@import "../../utilities/variables";

.homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100vw;
  top: 45px;

  .cocktail-display {
    position: absolute;
    top: 80px;
    overflow: hidden;
    min-height: 88vh;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
  }

  .cocktail-options {
    position: relative;
    width: 500px;

    &.active {
      top: -15px;
      padding-bottom: 500px;
    }

    &.inactive {
      top: 250px;
    }

    .input-name {
      text-align: left;
    }

    .filter-dropdown {
      margin-top: 20px;
    }
  }

  .checkbox {
    &.exact-match {
      width: 170px;
    }

    &.user-created {
      width: 238px;
    }

    margin-top: 10px;
    position: relative;
    left: -16px;
  }

  .cocktail-button {
    color: white;
    background-color: $coolpurp;
    font-family: "Roboto Condensed";
    letter-spacing: 3px;
    text-transform: none;
    font-weight: 400;
  }

  .show-more-cocktails-text {
    cursor: pointer;
    color: rgb(6, 69, 173);
    margin-bottom: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}
